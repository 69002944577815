<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="warning"
      @click="toggleModal()"
    >
      <i class="uil uil-minus-circle mr-1"></i>
      {{ $t('credits.direct_withdraw')}}
    </b-button>
    <b-modal
      v-model="isWithdrawModalActive"
      :title="`${$t('credits.direct_withdraw')}`"
      hide-footer
    >
      <ValidationObserver
        ref="withdrawForm"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="4"
            :label="`${$t('user.firstname')} ${$t('user.lastname')}`"
            label-for="name"
          >
            <b-form-input
              id="name"
              :value="`${player.firstname || ''} ${player.lastname || ''}`"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="4"
            label="เครดิตปัจจุบันกระเป๋าหลัก"
            label-for="credit"
          >
            <b-form-input
              id="credit"
              :value="player.credit || 0"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="4"
            label="เครดิตปัจจุบันกระเป๋าสะสม"
            label-for="holdCredit"
          >
            <b-form-input
              id="holdCredit"
              :value="player.holdCredit || 0"
              readonly
            ></b-form-input>
          </b-form-group>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('credits.credit_amount')"
            rules="required|min_value:1"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="4"
              :label="`${$t('credits.credit_amount')} *`"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model.number="transferForm.amount"
                type="text"
                :state="errors[0] ? false : null"
              ></b-form-input>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('fields.remark')"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="4"
              :label="`${$t('fields.remark')} *`"
              label-for="detail"
            >
              <b-form-textarea
                id="detail"
                v-model="transferForm.detail"
                :state="errors[0] ? false : null"
              ></b-form-textarea>
            </b-form-group>
          </ValidationProvider>
           <b-form-group
            label-cols-sm="12"
            label-cols-lg="4"
            class="mb-3"
          >
            <b-form-radio-group
              id="is_master"
              v-model="transferForm.wallet"
              name="depositSource"
            >
              <b-form-radio :value="1">
                ถอนจากกระเป๋าคงเหลือ
              </b-form-radio>
              <b-form-radio :value="2">
                ถอนจากกระเป๋าสะสม
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel')}}
            </b-button>
            <b-overlay
              :show="isWithdrawing"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm')}}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    noBtn: {
      type: Boolean,
      default: false,
    },
    playerId: {
      type: String,
      default: '',
    },
    player: {
      type: Object,
      default: () => {},
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isWithdrawModalActive: false,
      transferForm: {
        amount: 0,
        detail: '',
        wallet: 1,
      },
    }
  },
  computed: {
    ...mapState({
      isWithdrawing: (state) => state.player.isWithdrawingPlayerCredit,
    }),
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['withdrawPlayerCredit']),
    setData() {
      this.transferForm = {
        amount: 0,
        detail: '',
        wallet: 1,
      }
    },
    toggleModal() {
      this.isWithdrawModalActive = !this.isWithdrawModalActive
    },
    onCloseModal() {
      this.isWithdrawModalActive = false
    },
    async onSubmit() {
      this.transferForm.amount = +this.transferForm.amount
      await this.withdrawPlayerCredit({
        playerId: this.playerId,
        data: this.transferForm,
        search: this.search
      })
      this.onCloseModal()
      this.setData()
    },
  },
}
</script>